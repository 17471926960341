<template>
  <div :redraw="redraw">
    <div style="text-align: right"> 
      <button class="btn btn-success" @click="exportToExcel">Экспорт</button>
    </div>
    
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th>№</th>
        <th>ФИО</th>
        <th></th>
        <th v-for="subject in subjects" :key="subject.subjectId" class="rotate">
          {{subject.subjectName}}
        </th>
        <th>
          {{ $t("summaryReportOfStudentsProgress.teachersCouncilDecision") }}
        </th>
      </tr>
      </thead>
      <tbody v-for="(student, index) in students" :key="index">
      <tr>
        <td rowspan="7">{{index+1}}</td>
        <td rowspan="7">{{student.studentName}}</td>
        <td>
          {{ $t("summaryReportOfStudentsProgress.quarter.1") }}
        </td>
        <td v-for="subject in subjects" :key="subject.subjectId">
          {{showMark(student.studentId, 1, subject.subjectId)}}
        </td>        
        <td rowspan="7">
          <button class="btn btn-primary btn-sm btn-icon" @click="edit(student)">
            <i class="fa fa-pen"></i>
          </button>
          <br>
          {{student.teachersCouncilDecision}}
        </td>
      </tr>
      <tr v-for="(quarter, qIndex) in quarters" :key="qIndex">
        <td>
          {{ $t("summaryReportOfStudentsProgress.quarter." + quarter) }}
        </td>
        <td v-for="subject in subjects" :key="subject.subjectId">
          {{showMark(student.studentId, quarter, subject.subjectId)}}
        </td>
      </tr>
      </tbody>
    </table>

    <div>
      <b-modal id="summaryReportOfStudentsProgress-modal" ref="summaryReportOfStudentsProgress-modal">
        <template v-slot:modal-title>
          {{ $t("summaryReportOfStudentsProgress.teachersCouncilDecision") }}
        </template>
        <b-form>
          <div>
            {{form.studentName}}
          </div>
          <b-form-group>
            <template v-slot:label>
              {{ $t("summaryReportOfStudentsProgress.teachersCouncilDecision") }}
            </template>
            <b-form-input
                v-model="form.teachersCouncilDecision"
                :state="modelState.teachersCouncilDecision"
            ></b-form-input>
            <div class="invalid-feedback">{{ modelSaveErrors.teachersCouncilDecision }}</div>
          </b-form-group>
        </b-form>

        <template v-slot:modal-footer>
          <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
            {{ $t("common.save") }}
          </b-button>
          <b-button type="reset" @click="onReset" variant="danger">
            {{ $t("common.cancel") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>  
</template>
<style>
.dropped td {
  background-color: #F64E60;
}

th.rotate {
  writing-mode: vertical-rl;
}

</style>
<script>
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "SummaryReportOfStudentsProgressTable",
  components: {
  },
  data() {
    return {
      students:[],
      subjects:[],
      marks:{},
      quarters:[2, 3, 4, 5, 6, 7],
      redraw:0,
      form:{
        teachersCouncilDecision: "",
        studentName: "",
        studentId: 0,
      },
      modelState:{},
      modelSaveErrors:{},
      modalName: "summaryReportOfStudentsProgress-modal",
      groupId: -1
    };
  },
  mounted() {
      
  },
  methods: {
    redrawTable(groupId){
      this.groupId = groupId;
      let $this = this;
      
      ApiService.querySecured("schoolReports/summaryReportOfStudentsProgress", {
        params: { groupId: groupId }
      }).then(({data}) => {
        
        $this.students = data.students;
        $this.subjects = data.subjects;

        for(let i in $this.students){
          let studentId = $this.students[i].studentId;
          $this.marks[studentId] = {
            1:{},
            2:{},
            3:{},
            4:{},
            5:{},
            6:{},
            7:{}
          };
        }

        $this.prepareMark(data.quarter1Marks, 1)
        $this.prepareMark(data.quarter2Marks, 2)
        $this.prepareMark(data.quarter3Marks, 3)
        $this.prepareMark(data.quarter4Marks, 4)
        $this.prepareMark(data.annualMarks, 5)
        $this.prepareMark(data.examinationMarks, 6)
        $this.prepareMark(data.finalMarks, 7)
        
        $this.redraw = Math.random();
      }).catch((e) => {
        alert(e);
      });
    },
    exportToExcel(){
      ApiService.downloadSecured("schoolReports/summaryReportOfStudentsProgressExport?groupId=" + this.groupId)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Итоговая ведомость успеваемости учащихся.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    prepareMark(data, quarterIndex){
      
      for (let i in data){
        let mark = data[i];
        
        //console.log(typeof this.marks[mark.studentId]);
        //console.log(this.marks[mark.studentId]);
        
        if(typeof this.marks[mark.studentId] !== "undefined")
        {
          this.marks[mark.studentId][quarterIndex][mark.subjectId] = {
            mark: mark.mark,
            mark2: mark.mark2
          };
        }
      }
    },
    showMark(studentId, quarterIndex, subjectId){
      if(typeof this.marks[studentId] !== "undefined"
        && typeof this.marks[studentId][quarterIndex] !== "undefined"
        && typeof this.marks[studentId][quarterIndex][subjectId] !== "undefined"  
      ){
        return DictionariesService.displayQuarterMark(this.marks[studentId][quarterIndex][subjectId].mark)
             + (this.marks[studentId][quarterIndex][subjectId].mark2 != null ? 
                "/" + DictionariesService.displayQuarterMark(this.marks[studentId][quarterIndex][subjectId].mark) : ""
            );
      }
      
      return '';
    },
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("journal/additional/saveTeachersCouncilDecision", this.form)
          .then(function () {
            $this.$refs[$this.modalName].hide();
            $this.redrawTable($this.groupId);
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;  
              }
            }
          }).finally(function () {
        submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[this.modalName].hide();
    },
    cleanModel() {
      this.form = {};
    },
    edit(item) {
      this.form = {
        studentId:item.studentToGroupId,
        studentName:item.studentName,
        teachersCouncilDecision:item.teachersCouncilDecision,        
      };
      this.$refs[this.modalName].show();
    }
  }
};
</script>
